export const state = () => ({
  list: [],
  matchList: [],
  repaymentList: [],
  applicationData: {}
})
export const mutations = {
  setList(state, items) {
    state.list = items !== undefined ? items.records : []
  },
  setApplicationData(state, item) {
    state.applicationData = item
  },
  setMatchList(state, items) {
    state.matchList = items.records
  },
  setRepaymentList(state, items) {
    state.repaymentList = items
  },
  setTags(state, items) {
    state.tags = JSON.parse(JSON.stringify(items));
  },
  setCompanies(state, items) {
    state.companies = items
  },
  setDetail(state, items) {
    state.detail = items
  }
}
export const actions = {
  fetchList: async function ({ commit }, payload) {
    const type = payload.type || 'loan'
    const params = payload.params || {}
    const { data } = await this.$axios.post('/' + type + '/list', params)
    commit('setList', data.data)
  },
  fetchApplicationData: async function ({ commit }, params) {
    const paramStr = new URLSearchParams(params).toString();
    try {
      const { data } = await this.$axios.get('/form/getByToken?' + paramStr)
      commit('setApplicationData', (typeof data.formInfo === 'string') ? JSON.parse(data.formInfo) : data.formInfo)
    } catch (e) {
      console.error(e);
    }
  },
  fetchMatchList: async function ({ commit }, payload) {
    const type = payload.type || 'loan'
    const params = payload.params || {}
    const { data } = await this.$axios.post('/' + type + '/match', params)
    commit('setMatchList', data.data)
  },
  fetchRepaymentList: async function ({ commit }, payload) {
    const path = payload.path || 'scheduleByPLoanForTenor'
    const params = payload.params || {}
    const { data } = await this.$axios.post('/repayment/' + path, params)
    commit('setRepaymentList', data.data)
  },
  fetchTags: async function ({ commit }, payload) {
    const type = payload.type || 'loan'
    const { data } = await this.$axios.get('/tag/getTagsByType?type=' + type)
    commit('setTags', data.data)
  },
  fetchCompanies: async function ({ commit }, payload) {
    const type = payload.type || 'loan'
    const { data } = await this.$axios.get('/company/getCompaniesByType?type=' + type)
    commit('setCompanies', data.data)
  },
  fetchDetail: async function ({ commit }, payload) {
    const id = payload.id
    const type = payload.type
    const { data } = await this.$axios.get('/' + type + '/' + id)
    commit('setDetail', data.data)
  }
}
