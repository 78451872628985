import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e68210f = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _29278596 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _23cadf84 = () => interopDefault(import('../pages/card/index.vue' /* webpackChunkName: "pages/card/index" */))
const _622823bc = () => interopDefault(import('../pages/insurance/index.vue' /* webpackChunkName: "pages/insurance/index" */))
const _3a0cfdea = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2b8b9184 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _731fc590 = () => interopDefault(import('../pages/mortgage/index.vue' /* webpackChunkName: "pages/mortgage/index" */))
const _174e8188 = () => interopDefault(import('../pages/mortgage-match/index.vue' /* webpackChunkName: "pages/mortgage-match/index" */))
const _cfefc3be = () => interopDefault(import('../pages/p-loan/index.vue' /* webpackChunkName: "pages/p-loan/index" */))
const _0dcb2dea = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _8ff8e0ea = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _c31214ea = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _84fc80ac = () => interopDefault(import('../pages/query/index.vue' /* webpackChunkName: "pages/query/index" */))
const _5ffd22d7 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _5a5c579c = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _66529d08 = () => interopDefault(import('../pages/vendor/index.vue' /* webpackChunkName: "pages/vendor/index" */))
const _364cf4dc = () => interopDefault(import('../pages/application/done.vue' /* webpackChunkName: "pages/application/done" */))
const _3f7acf40 = () => interopDefault(import('../pages/application/funding/index.vue' /* webpackChunkName: "pages/application/funding/index" */))
const _6145d9a2 = () => interopDefault(import('../pages/application/mortgage/index.vue' /* webpackChunkName: "pages/application/mortgage/index" */))
const _742a4780 = () => interopDefault(import('../pages/application/p-loan/index.vue' /* webpackChunkName: "pages/application/p-loan/index" */))
const _ab8f0eb0 = () => interopDefault(import('../pages/blog/all/index.vue' /* webpackChunkName: "pages/blog/all/index" */))
const _0acbc17c = () => interopDefault(import('../pages/enquiry/done/index.vue' /* webpackChunkName: "pages/enquiry/done/index" */))
const _59fa4d93 = () => interopDefault(import('../pages/enquiry/investment/index.vue' /* webpackChunkName: "pages/enquiry/investment/index" */))
const _1eb43bfd = () => interopDefault(import('../pages/form/ua_prefill.vue' /* webpackChunkName: "pages/form/ua_prefill" */))
const _3b27b7f8 = () => interopDefault(import('../pages/insurance/auto/index.vue' /* webpackChunkName: "pages/insurance/auto/index" */))
const _61bcdb39 = () => interopDefault(import('../pages/insurance/decoration/index.vue' /* webpackChunkName: "pages/insurance/decoration/index" */))
const _027ff3b7 = () => interopDefault(import('../pages/insurance/helper/index.vue' /* webpackChunkName: "pages/insurance/helper/index" */))
const _12ff271d = () => interopDefault(import('../pages/insurance/labor/index.vue' /* webpackChunkName: "pages/insurance/labor/index" */))
const _20d792e4 = () => interopDefault(import('../pages/insurance/store/index.vue' /* webpackChunkName: "pages/insurance/store/index" */))
const _eaade7ba = () => interopDefault(import('../pages/insurance/travel/index.vue' /* webpackChunkName: "pages/insurance/travel/index" */))
const _340b4798 = () => interopDefault(import('../pages/login/forgotpassword/index.vue' /* webpackChunkName: "pages/login/forgotpassword/index" */))
const _e19b91dc = () => interopDefault(import('../pages/oauth2/redirect/index.vue' /* webpackChunkName: "pages/oauth2/redirect/index" */))
const _1a5958f4 = () => interopDefault(import('../pages/p-loan/calculator/index.vue' /* webpackChunkName: "pages/p-loan/calculator/index" */))
const _4ba45e77 = () => interopDefault(import('../pages/p-loan/compare/index.vue' /* webpackChunkName: "pages/p-loan/compare/index" */))
const _8cb8e4f0 = () => interopDefault(import('../pages/p-loan/form/index.vue' /* webpackChunkName: "pages/p-loan/form/index" */))
const _bd543c78 = () => interopDefault(import('../pages/p-loan/index copy.vue' /* webpackChunkName: "pages/p-loan/index copy" */))
const _163c7bc0 = () => interopDefault(import('../pages/p-loan/lead/index.vue' /* webpackChunkName: "pages/p-loan/lead/index" */))
const _83518064 = () => interopDefault(import('../pages/p-loan/recommend/index.vue' /* webpackChunkName: "pages/p-loan/recommend/index" */))
const _4c31d1e4 = () => interopDefault(import('../pages/p-loan/short/index.vue' /* webpackChunkName: "pages/p-loan/short/index" */))
const _08f2855c = () => interopDefault(import('../pages/profile/contact/index.vue' /* webpackChunkName: "pages/profile/contact/index" */))
const _a41594c6 = () => interopDefault(import('../pages/profile/loan_status/index.vue' /* webpackChunkName: "pages/profile/loan_status/index" */))
const _24d2b53f = () => interopDefault(import('../pages/profile/requirement/index.vue' /* webpackChunkName: "pages/profile/requirement/index" */))
const _254ca8e6 = () => interopDefault(import('../pages/profile/resetpassword/index.vue' /* webpackChunkName: "pages/profile/resetpassword/index" */))
const _dd90456e = () => interopDefault(import('../pages/profile/reward/index.vue' /* webpackChunkName: "pages/profile/reward/index" */))
const _513af6e0 = () => interopDefault(import('../pages/vendor/checkid/index.vue' /* webpackChunkName: "pages/vendor/checkid/index" */))
const _3f6fe0d6 = () => interopDefault(import('../pages/vendor/login/index.vue' /* webpackChunkName: "pages/vendor/login/index" */))
const _f386d0c6 = () => interopDefault(import('../pages/application/components/CommonFields.vue' /* webpackChunkName: "pages/application/components/CommonFields" */))
const _057d5c84 = () => interopDefault(import('../pages/application/components/ContactTime.vue' /* webpackChunkName: "pages/application/components/ContactTime" */))
const _aafee952 = () => interopDefault(import('../pages/application/components/Identity.vue' /* webpackChunkName: "pages/application/components/Identity" */))
const _0525db9c = () => interopDefault(import('../pages/application/components/Step1.vue' /* webpackChunkName: "pages/application/components/Step1" */))
const _0533f31d = () => interopDefault(import('../pages/application/components/Step2.vue' /* webpackChunkName: "pages/application/components/Step2" */))
const _05420a9e = () => interopDefault(import('../pages/application/components/Step3.vue' /* webpackChunkName: "pages/application/components/Step3" */))
const _0550221f = () => interopDefault(import('../pages/application/components/Step4.vue' /* webpackChunkName: "pages/application/components/Step4" */))
const _761cfe72 = () => interopDefault(import('../pages/application/components/StepShort.vue' /* webpackChunkName: "pages/application/components/StepShort" */))
const _2fbb7402 = () => interopDefault(import('../pages/insurance/travel/apply/index.vue' /* webpackChunkName: "pages/insurance/travel/apply/index" */))
const _6161d094 = () => interopDefault(import('../pages/application/additional/components/Step1.vue' /* webpackChunkName: "pages/application/additional/components/Step1" */))
const _617dff96 = () => interopDefault(import('../pages/application/additional/components/Step3.vue' /* webpackChunkName: "pages/application/additional/components/Step3" */))
const _0666152a = () => interopDefault(import('../pages/application/approve/components/ApproveForm.vue' /* webpackChunkName: "pages/application/approve/components/ApproveForm" */))
const _4a1bc2f2 = () => interopDefault(import('../pages/application/approve/components/BorrowerInfo.vue' /* webpackChunkName: "pages/application/approve/components/BorrowerInfo" */))
const _9ecc6e7e = () => interopDefault(import('../pages/application/approve/components/LenderInfo.vue' /* webpackChunkName: "pages/application/approve/components/LenderInfo" */))
const _604a7151 = () => interopDefault(import('../pages/application/commercial/components/Step1.vue' /* webpackChunkName: "pages/application/commercial/components/Step1" */))
const _605888d2 = () => interopDefault(import('../pages/application/commercial/components/Step2.vue' /* webpackChunkName: "pages/application/commercial/components/Step2" */))
const _6066a053 = () => interopDefault(import('../pages/application/commercial/components/Step3.vue' /* webpackChunkName: "pages/application/commercial/components/Step3" */))
const _6074b7d4 = () => interopDefault(import('../pages/application/commercial/components/Step4.vue' /* webpackChunkName: "pages/application/commercial/components/Step4" */))
const _2bba33fc = () => interopDefault(import('../pages/application/commercial/components/StepShort.vue' /* webpackChunkName: "pages/application/commercial/components/StepShort" */))
const _50cc3924 = () => interopDefault(import('../pages/application/funding/components/Step1.vue' /* webpackChunkName: "pages/application/funding/components/Step1" */))
const _50b00a22 = () => interopDefault(import('../pages/application/funding/components/Step2.vue' /* webpackChunkName: "pages/application/funding/components/Step2" */))
const _5093db20 = () => interopDefault(import('../pages/application/funding/components/Step3.vue' /* webpackChunkName: "pages/application/funding/components/Step3" */))
const _35b3e13f = () => interopDefault(import('../pages/application/mortgage/components/Step1.vue' /* webpackChunkName: "pages/application/mortgage/components/Step1" */))
const _35c1f8c0 = () => interopDefault(import('../pages/application/mortgage/components/Step2.vue' /* webpackChunkName: "pages/application/mortgage/components/Step2" */))
const _35d01041 = () => interopDefault(import('../pages/application/mortgage/components/Step3.vue' /* webpackChunkName: "pages/application/mortgage/components/Step3" */))
const _35de27c2 = () => interopDefault(import('../pages/application/mortgage/components/Step4.vue' /* webpackChunkName: "pages/application/mortgage/components/Step4" */))
const _0aceff72 = () => interopDefault(import('../pages/application/submitted/components/ApplySubmitted.vue' /* webpackChunkName: "pages/application/submitted/components/ApplySubmitted" */))
const _325321ae = () => interopDefault(import('../pages/p-loan/lead/components/Step1.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step1" */))
const _3261392f = () => interopDefault(import('../pages/p-loan/lead/components/Step2.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step2" */))
const _326f50b0 = () => interopDefault(import('../pages/p-loan/lead/components/Step3.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step3" */))
const _327d6831 = () => interopDefault(import('../pages/p-loan/lead/components/Step4.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step4" */))
const _328b7fb2 = () => interopDefault(import('../pages/p-loan/lead/components/Step5.vue' /* webpackChunkName: "pages/p-loan/lead/components/Step5" */))
const _18655098 = () => interopDefault(import('../pages/p-loan/lead/components/Vendors.vue' /* webpackChunkName: "pages/p-loan/lead/components/Vendors" */))
const _4cea1702 = () => interopDefault(import('../pages/p-loan/short/components/Step0.vue' /* webpackChunkName: "pages/p-loan/short/components/Step0" */))
const _4ccde800 = () => interopDefault(import('../pages/p-loan/short/components/Step1.vue' /* webpackChunkName: "pages/p-loan/short/components/Step1" */))
const _4cb1b8fe = () => interopDefault(import('../pages/p-loan/short/components/Step2.vue' /* webpackChunkName: "pages/p-loan/short/components/Step2" */))
const _4c9589fc = () => interopDefault(import('../pages/p-loan/short/components/Step3.vue' /* webpackChunkName: "pages/p-loan/short/components/Step3" */))
const _edca077e = () => interopDefault(import('../pages/application/approve/second/_slug.vue' /* webpackChunkName: "pages/application/approve/second/_slug" */))
const _5521bc28 = () => interopDefault(import('../pages/application/offer/confirm/_slug.vue' /* webpackChunkName: "pages/application/offer/confirm/_slug" */))
const _2e04d2ba = () => interopDefault(import('../pages/insurance/travel/apply/_slug.vue' /* webpackChunkName: "pages/insurance/travel/apply/_slug" */))
const _1fb4c5b2 = () => interopDefault(import('../pages/application/additional/_slug.vue' /* webpackChunkName: "pages/application/additional/_slug" */))
const _c7e1d930 = () => interopDefault(import('../pages/application/approve/_slug.vue' /* webpackChunkName: "pages/application/approve/_slug" */))
const _65714cbe = () => interopDefault(import('../pages/application/checkid/_slug.vue' /* webpackChunkName: "pages/application/checkid/_slug" */))
const _b96d8dd6 = () => interopDefault(import('../pages/application/commercial/_slug.vue' /* webpackChunkName: "pages/application/commercial/_slug" */))
const _0301831b = () => interopDefault(import('../pages/application/confirm/_slug.vue' /* webpackChunkName: "pages/application/confirm/_slug" */))
const _070cd15e = () => interopDefault(import('../pages/application/enquiry/_slug.vue' /* webpackChunkName: "pages/application/enquiry/_slug" */))
const _2a285990 = () => interopDefault(import('../pages/application/feedback/_slug.vue' /* webpackChunkName: "pages/application/feedback/_slug" */))
const _64b31c32 = () => interopDefault(import('../pages/application/mortgage/_slug.vue' /* webpackChunkName: "pages/application/mortgage/_slug" */))
const _32a5e5b7 = () => interopDefault(import('../pages/application/offer/_slug.vue' /* webpackChunkName: "pages/application/offer/_slug" */))
const _4dc8eed4 = () => interopDefault(import('../pages/application/property_owner/_slug.vue' /* webpackChunkName: "pages/application/property_owner/_slug" */))
const _73b79628 = () => interopDefault(import('../pages/application/result/_slug.vue' /* webpackChunkName: "pages/application/result/_slug" */))
const _38fc3596 = () => interopDefault(import('../pages/application/submitted/_slug.vue' /* webpackChunkName: "pages/application/submitted/_slug" */))
const _3e81ee38 = () => interopDefault(import('../pages/p-loan/apply/_slug.vue' /* webpackChunkName: "pages/p-loan/apply/_slug" */))
const _5cb17fc7 = () => interopDefault(import('../pages/account/_slug.vue' /* webpackChunkName: "pages/account/_slug" */))
const _25094faa = () => interopDefault(import('../pages/application/_slug.vue' /* webpackChunkName: "pages/application/_slug" */))
const _2770e44e = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _22143e3c = () => interopDefault(import('../pages/card/_slug.vue' /* webpackChunkName: "pages/card/_slug" */))
const _0ee23ef0 = () => interopDefault(import('../pages/form/_slug.vue' /* webpackChunkName: "pages/form/_slug" */))
const _60718274 = () => interopDefault(import('../pages/insurance/_slug.vue' /* webpackChunkName: "pages/insurance/_slug" */))
const _7afed331 = () => interopDefault(import('../pages/landing/_slug.vue' /* webpackChunkName: "pages/landing/_slug" */))
const _71692448 = () => interopDefault(import('../pages/mortgage/_slug.vue' /* webpackChunkName: "pages/mortgage/_slug" */))
const _d35d064e = () => interopDefault(import('../pages/p-loan/_slug.vue' /* webpackChunkName: "pages/p-loan/_slug" */))
const _3f55dd71 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _5e68210f,
    name: "account"
  }, {
    path: "/blog",
    component: _29278596,
    name: "blog"
  }, {
    path: "/card",
    component: _23cadf84,
    name: "card"
  }, {
    path: "/insurance",
    component: _622823bc,
    name: "insurance"
  }, {
    path: "/login",
    component: _3a0cfdea,
    name: "login"
  }, {
    path: "/logout",
    component: _2b8b9184,
    name: "logout"
  }, {
    path: "/mortgage",
    component: _731fc590,
    name: "mortgage"
  }, {
    path: "/mortgage-match",
    component: _174e8188,
    name: "mortgage-match"
  }, {
    path: "/p-loan",
    component: _cfefc3be,
    name: "p-loan"
  }, {
    path: "/partner",
    component: _0dcb2dea,
    name: "partner"
  }, {
    path: "/privacy-policy",
    component: _8ff8e0ea,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _c31214ea,
    name: "profile"
  }, {
    path: "/query",
    component: _84fc80ac,
    name: "query"
  }, {
    path: "/register",
    component: _5ffd22d7,
    name: "register"
  }, {
    path: "/terms-conditions",
    component: _5a5c579c,
    name: "terms-conditions"
  }, {
    path: "/vendor",
    component: _66529d08,
    name: "vendor"
  }, {
    path: "/application/done",
    component: _364cf4dc,
    name: "application-done"
  }, {
    path: "/application/funding",
    component: _3f7acf40,
    name: "application-funding"
  }, {
    path: "/application/mortgage",
    component: _6145d9a2,
    name: "application-mortgage"
  }, {
    path: "/application/p-loan",
    component: _742a4780,
    name: "application-p-loan"
  }, {
    path: "/blog/all",
    component: _ab8f0eb0,
    name: "blog-all"
  }, {
    path: "/enquiry/done",
    component: _0acbc17c,
    name: "enquiry-done"
  }, {
    path: "/enquiry/investment",
    component: _59fa4d93,
    name: "enquiry-investment"
  }, {
    path: "/form/ua_prefill",
    component: _1eb43bfd,
    name: "form-ua_prefill"
  }, {
    path: "/insurance/auto",
    component: _3b27b7f8,
    name: "insurance-auto"
  }, {
    path: "/insurance/decoration",
    component: _61bcdb39,
    name: "insurance-decoration"
  }, {
    path: "/insurance/helper",
    component: _027ff3b7,
    name: "insurance-helper"
  }, {
    path: "/insurance/labor",
    component: _12ff271d,
    name: "insurance-labor"
  }, {
    path: "/insurance/store",
    component: _20d792e4,
    name: "insurance-store"
  }, {
    path: "/insurance/travel",
    component: _eaade7ba,
    name: "insurance-travel"
  }, {
    path: "/login/forgotpassword",
    component: _340b4798,
    name: "login-forgotpassword"
  }, {
    path: "/oauth2/redirect",
    component: _e19b91dc,
    name: "oauth2-redirect"
  }, {
    path: "/p-loan/calculator",
    component: _1a5958f4,
    name: "p-loan-calculator"
  }, {
    path: "/p-loan/compare",
    component: _4ba45e77,
    name: "p-loan-compare"
  }, {
    path: "/p-loan/form",
    component: _8cb8e4f0,
    name: "p-loan-form"
  }, {
    path: "/p-loan/index%20copy",
    component: _bd543c78,
    name: "p-loan-index copy"
  }, {
    path: "/p-loan/lead",
    component: _163c7bc0,
    name: "p-loan-lead"
  }, {
    path: "/p-loan/recommend",
    component: _83518064,
    name: "p-loan-recommend"
  }, {
    path: "/p-loan/short",
    component: _4c31d1e4,
    name: "p-loan-short"
  }, {
    path: "/profile/contact",
    component: _08f2855c,
    name: "profile-contact"
  }, {
    path: "/profile/loan_status",
    component: _a41594c6,
    name: "profile-loan_status"
  }, {
    path: "/profile/requirement",
    component: _24d2b53f,
    name: "profile-requirement"
  }, {
    path: "/profile/resetpassword",
    component: _254ca8e6,
    name: "profile-resetpassword"
  }, {
    path: "/profile/reward",
    component: _dd90456e,
    name: "profile-reward"
  }, {
    path: "/vendor/checkid",
    component: _513af6e0,
    name: "vendor-checkid"
  }, {
    path: "/vendor/login",
    component: _3f6fe0d6,
    name: "vendor-login"
  }, {
    path: "/application/components/CommonFields",
    component: _f386d0c6,
    name: "application-components-CommonFields"
  }, {
    path: "/application/components/ContactTime",
    component: _057d5c84,
    name: "application-components-ContactTime"
  }, {
    path: "/application/components/Identity",
    component: _aafee952,
    name: "application-components-Identity"
  }, {
    path: "/application/components/Step1",
    component: _0525db9c,
    name: "application-components-Step1"
  }, {
    path: "/application/components/Step2",
    component: _0533f31d,
    name: "application-components-Step2"
  }, {
    path: "/application/components/Step3",
    component: _05420a9e,
    name: "application-components-Step3"
  }, {
    path: "/application/components/Step4",
    component: _0550221f,
    name: "application-components-Step4"
  }, {
    path: "/application/components/StepShort",
    component: _761cfe72,
    name: "application-components-StepShort"
  }, {
    path: "/insurance/travel/apply",
    component: _2fbb7402,
    name: "insurance-travel-apply"
  }, {
    path: "/application/additional/components/Step1",
    component: _6161d094,
    name: "application-additional-components-Step1"
  }, {
    path: "/application/additional/components/Step3",
    component: _617dff96,
    name: "application-additional-components-Step3"
  }, {
    path: "/application/approve/components/ApproveForm",
    component: _0666152a,
    name: "application-approve-components-ApproveForm"
  }, {
    path: "/application/approve/components/BorrowerInfo",
    component: _4a1bc2f2,
    name: "application-approve-components-BorrowerInfo"
  }, {
    path: "/application/approve/components/LenderInfo",
    component: _9ecc6e7e,
    name: "application-approve-components-LenderInfo"
  }, {
    path: "/application/commercial/components/Step1",
    component: _604a7151,
    name: "application-commercial-components-Step1"
  }, {
    path: "/application/commercial/components/Step2",
    component: _605888d2,
    name: "application-commercial-components-Step2"
  }, {
    path: "/application/commercial/components/Step3",
    component: _6066a053,
    name: "application-commercial-components-Step3"
  }, {
    path: "/application/commercial/components/Step4",
    component: _6074b7d4,
    name: "application-commercial-components-Step4"
  }, {
    path: "/application/commercial/components/StepShort",
    component: _2bba33fc,
    name: "application-commercial-components-StepShort"
  }, {
    path: "/application/funding/components/Step1",
    component: _50cc3924,
    name: "application-funding-components-Step1"
  }, {
    path: "/application/funding/components/Step2",
    component: _50b00a22,
    name: "application-funding-components-Step2"
  }, {
    path: "/application/funding/components/Step3",
    component: _5093db20,
    name: "application-funding-components-Step3"
  }, {
    path: "/application/mortgage/components/Step1",
    component: _35b3e13f,
    name: "application-mortgage-components-Step1"
  }, {
    path: "/application/mortgage/components/Step2",
    component: _35c1f8c0,
    name: "application-mortgage-components-Step2"
  }, {
    path: "/application/mortgage/components/Step3",
    component: _35d01041,
    name: "application-mortgage-components-Step3"
  }, {
    path: "/application/mortgage/components/Step4",
    component: _35de27c2,
    name: "application-mortgage-components-Step4"
  }, {
    path: "/application/submitted/components/ApplySubmitted",
    component: _0aceff72,
    name: "application-submitted-components-ApplySubmitted"
  }, {
    path: "/p-loan/lead/components/Step1",
    component: _325321ae,
    name: "p-loan-lead-components-Step1"
  }, {
    path: "/p-loan/lead/components/Step2",
    component: _3261392f,
    name: "p-loan-lead-components-Step2"
  }, {
    path: "/p-loan/lead/components/Step3",
    component: _326f50b0,
    name: "p-loan-lead-components-Step3"
  }, {
    path: "/p-loan/lead/components/Step4",
    component: _327d6831,
    name: "p-loan-lead-components-Step4"
  }, {
    path: "/p-loan/lead/components/Step5",
    component: _328b7fb2,
    name: "p-loan-lead-components-Step5"
  }, {
    path: "/p-loan/lead/components/Vendors",
    component: _18655098,
    name: "p-loan-lead-components-Vendors"
  }, {
    path: "/p-loan/short/components/Step0",
    component: _4cea1702,
    name: "p-loan-short-components-Step0"
  }, {
    path: "/p-loan/short/components/Step1",
    component: _4ccde800,
    name: "p-loan-short-components-Step1"
  }, {
    path: "/p-loan/short/components/Step2",
    component: _4cb1b8fe,
    name: "p-loan-short-components-Step2"
  }, {
    path: "/p-loan/short/components/Step3",
    component: _4c9589fc,
    name: "p-loan-short-components-Step3"
  }, {
    path: "/application/approve/second/:slug?",
    component: _edca077e,
    name: "application-approve-second-slug"
  }, {
    path: "/application/offer/confirm/:slug?",
    component: _5521bc28,
    name: "application-offer-confirm-slug"
  }, {
    path: "/insurance/travel/apply/:slug",
    component: _2e04d2ba,
    name: "insurance-travel-apply-slug"
  }, {
    path: "/application/additional/:slug?",
    component: _1fb4c5b2,
    name: "application-additional-slug"
  }, {
    path: "/application/approve/:slug?",
    component: _c7e1d930,
    name: "application-approve-slug"
  }, {
    path: "/application/checkid/:slug?",
    component: _65714cbe,
    name: "application-checkid-slug"
  }, {
    path: "/application/commercial/:slug?",
    component: _b96d8dd6,
    name: "application-commercial-slug"
  }, {
    path: "/application/confirm/:slug?",
    component: _0301831b,
    name: "application-confirm-slug"
  }, {
    path: "/application/enquiry/:slug?",
    component: _070cd15e,
    name: "application-enquiry-slug"
  }, {
    path: "/application/feedback/:slug?",
    component: _2a285990,
    name: "application-feedback-slug"
  }, {
    path: "/application/mortgage/:slug",
    component: _64b31c32,
    name: "application-mortgage-slug"
  }, {
    path: "/application/offer/:slug?",
    component: _32a5e5b7,
    name: "application-offer-slug"
  }, {
    path: "/application/property_owner/:slug?",
    component: _4dc8eed4,
    name: "application-property_owner-slug"
  }, {
    path: "/application/result/:slug?",
    component: _73b79628,
    name: "application-result-slug"
  }, {
    path: "/application/submitted/:slug?",
    component: _38fc3596,
    name: "application-submitted-slug"
  }, {
    path: "/p-loan/apply/:slug",
    component: _3e81ee38,
    name: "p-loan-apply-slug"
  }, {
    path: "/account/:slug",
    component: _5cb17fc7,
    name: "account-slug"
  }, {
    path: "/application/:slug?",
    component: _25094faa,
    name: "application-slug"
  }, {
    path: "/blog/:slug",
    component: _2770e44e,
    name: "blog-slug"
  }, {
    path: "/card/:slug",
    component: _22143e3c,
    name: "card-slug"
  }, {
    path: "/form/:slug?",
    component: _0ee23ef0,
    name: "form-slug"
  }, {
    path: "/insurance/:slug",
    component: _60718274,
    name: "insurance-slug"
  }, {
    path: "/landing/:slug?",
    component: _7afed331,
    name: "landing-slug"
  }, {
    path: "/mortgage/:slug",
    component: _71692448,
    name: "mortgage-slug"
  }, {
    path: "/p-loan/:slug?",
    component: _d35d064e,
    name: "p-loan-slug"
  }, {
    path: "/",
    component: _3f55dd71,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
